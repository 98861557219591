import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from 'react-google-recaptcha';

const Form = () => {
  const form = useRef();
  const recaptchaRef = useRef(null);
  const [captchaValid, setCaptchaValid] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      toast('Please verify the that you are not a robot.', {
        position: 'top-center',
        autoClose: 5000,
      });
      return;
    }
    emailjs
      .sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, {
        publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      })
      .then(
        (response) => {
          if (response.status === 200) {
            toast('Thank you for your message! I will get back to you as soon as possible.', {
              position: 'top-center',
              autoClose: 5000,
            });
          } else {
            toast('Something went wrong. Please try again.', {
              position: 'top-center',
              autoClose: 5000,
            });
          }

          form.current.reset();
          recaptchaRef.current.reset();
          setCaptchaValid(false);
        },
        (error) => {
          toast('Something went wrong. Please try again.', {
            position: 'top-center',
            autoClose: 5000,
          });
        }
      );
  };
  const onCaptchaChange = (value) => {
    setCaptchaValid(!!value);
  };
  return (
    <form
      ref={form}
      onSubmit={sendEmail}
      className="flex flex-col p-6 rounded-2xl justify-center items-center bg-teal-100 bg-opacity-50 text-stone-950 w-full max-w-lg mx-auto shadow-lg"
    >
      <ToastContainer />
      <h2 className="text-3xl font-bold mb-4">Contact</h2>

      <label htmlFor="name" className="w-full mb-2 font-semibold">
        Name:
      </label>
      <input
        type="text"
        id="from_name"
        name="from_name"
        required
        className="w-full p-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
      />

      <label htmlFor="email" className="w-full mb-2 font-semibold">
        Email:
      </label>
      <input
        type="email"
        required
        id="reply_to"
        name="reply_to"
        className="w-full p-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
      />

      <label htmlFor="message" className="w-full mb-2 font-semibold">
        Message:
      </label>
      <textarea
        id="message"
        name="message"
        className="w-full p-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
      ></textarea>
      <ReCAPTCHA
        ref={recaptchaRef}
        required
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        onChange={onCaptchaChange}
        className="mb-4"
        size="compact"
      />
      <button
        type="submit"
        value="Send"
        className="w-full py-2 px-4 bg-teal-500 text-white font-semibold rounded-lg hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-500"
        disabled={!captchaValid}
      >
        Submit
      </button>
    </form>
  );
};

export default Form;
