import React from 'react';
import Slider from 'react-slick';

const reviews = [
  {
    name: 'Mimi, 56 years young',
    text: `My 3-week training with Mirka was tough but an utter joy. The results speak for themselves. She gave me encouragement along the way. She pushed me hard but respected my limits. Always smiling, always pleasant. I shall be forever grateful for the time and effort she put in for me. She has now taught me how and what to do. I would recommend her a hundred times over. I came back to Egypt hearing from family and friends how much better I look and that I have definitely lost some weight. Thank you to the best coach ever!`,
  },
  {
    name: 'Oona, 35 years young',
    text: `Completing the online program with Mirka was a game-changer. I've built muscle mass, lost weight, and gained so much energy. Mirka’s guidance and support were fantastic. I'm thrilled with the results and excited to continue this healthy lifestyle. Highly recommend!`,
  },
  {
    name: 'Rema, 41 years young ',
    text: 'Mirka’s program helped me achieve my fitness goals with a structured training schedule, working out at least three times a week. I consistently targeted different muscle groups and improved my cardiovascular health. Unlike other fitness courses, this program was tailored to my personal needs and lifestyle, ensuring long-term engagement. The regular check-ins with Mirka kept me focused and disciplined. I highly recommend this program for anyone looking to prioritize their fitness and overall well-being amidst a busy lifestyle',
  },
  {
    name: 'Sabine, 32 years young',
    text: 'I met Mirka at Titan Fitness in Thailand, where she was my trainer for two camps. Her “no BS” approach stood out—knowing exactly when to push me. She respected my limits, which kept me committed and motivated. My goal with her online coaching was to build a gym routine and gain confidence. Mirka’s workouts were accommodating, efficient, and manageable, helping me establish a routine and go to the gym without anxiety. Her regular check-ins showed she genuinely cares. Mirka’s program made me stronger and more confident. I highly recommend her coaching for anyone looking for personalized, supportive training.',
  },
];

const Reviews = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
  };

  return (
    <div className="bg-green-200 p-6 rounded-lg shadow-md mx-auto mt-10  w-full justify-center text-center ">
      {/*  <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">Client Reviews</h2> */}
      <Slider {...settings}>
        {reviews.map((review, index) => {
          return (
            <div key={index} className="mb-6 justify-between items-center text-center flex flex-col h-full">
              <h3 className="text-xl mb-4 font-semibold text-green-600">{review.name}</h3>
              <p className="text-gray-700">{review.text}</p>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default Reviews;
