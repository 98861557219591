const Navbar = ({ toggleNavbar, isMobile }) => {
  const listStyle = 'mb-4 md:mb-0 hover:scale-125 ease-in-out duration-200 text-xl';
  return (
    <div className="z-999 navbar courgette-regular bg-gray-800 text-white p-4 md:sticky md:top-0 md:h-screen md:w-64 justify-center items-center flex flex-col">
      {/* <h1 className="text-2xl font-bold mb-6 md:mb-0">Portfolio</h1> */}

      <ul className="md:flex md:flex-col md:space-y-8  space-x-4 md:space-x-0 items-center">
        {!isMobile ? (
          <img src={'./images/profile.jpg'} alt="Mirka" className="rounded-full    max-w-48 shadow-xl shadow-sky-800" />
        ) : null}
        <li className="mb-0  hover:scale-125 ease-in-out duration-200 text-medium md:text-xl">
          <a href="#home" onClick={toggleNavbar}>
            Welcome
          </a>
        </li>

        <li className="mb-0 md:mb-0 hover:scale-125 ease-in-out duration-200 text-medium md:text-xl">
          <a onClick={toggleNavbar} href="#about">
            About me
          </a>
        </li>
        <li className="mb-0 md:mb-0 hover:scale-125 ease-in-out duration-200 text-medium md:text-xl">
          <a onClick={toggleNavbar} href="#projects">
            Project
          </a>
        </li>
        <li className="mb-0 md:mb-0 hover:scale-125 ease-in-out duration-200 text-medium md:text-xl">
          <a onClick={toggleNavbar} href="#contact">
            Contact me
          </a>
        </li>
        <a href="https://www.instagram.com/paradise_energy/" target="_blank" rel="noreferrer">
          <img
            src={'./images/insta.png'}
            alt="logo"
            className="w-9 h-8 md:w-16 md:h-16 md-2 md:m-6 hover:scale-125 ease-in-out duration-300 mr-2 md:mr-0"
          />
        </a>
      </ul>
    </div>
  );
};

export default Navbar;
