import './App.css';
import { useState, useEffect, useRef } from 'react';
import Navbar from './components/navbar';
import Content from './components/content';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactGA from 'react-ga4';

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const contentRef = useRef(null); // Ref scrollattavalle sisältöelementille
  const lastScrollTop = useRef(0);

  const TRACKING_ID = 'G-GC0F1TVYYJ'; // Replace with your Google Analytics tracking ID
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);

    //ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isMobile) {
      return;
    }
    setIsOpen(true);
    const handleScroll = () => {
      const navbar = document.querySelector('.navbar');
      if (!navbar) {
        console.error('Navbar element not found');
        return;
      }
      const scrollTop = contentRef.current.scrollTop;
      if (scrollTop > lastScrollTop.current) {
        navbar.classList.add('navbar-hide');
      } else {
        setIsOpen(true);
        navbar.classList.remove('navbar-hide');
      }
      lastScrollTop.current = scrollTop;
    };

    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (contentElement) {
        contentElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isMobile]);
  return (
    <div className="relative flex h-screen overflow-hidden">
      <div className={`absolute md:static top-0 left-0 w-full md:w-64 ${isOpen ? 'block' : 'hidden'} md:block`}>
        <Navbar toggleNavbar={toggleNavbar} isMobile={isMobile} />
      </div>
      <div
        ref={contentRef}
        className={`flex-1 overflow-auto hide-scrollbar ${!isMobile ? 'snap-y snap-mandatory' : ''}`}
      >
        <div className="h-full">
          <Content isMobile={isMobile} />
        </div>
      </div>
    </div>
  );
};

export default App;
