import React, { Suspense, lazy } from 'react';
import Form from './form';
import Reviews from './reviews';
//import Karuselli from './carousel';
const Karuselli = lazy(() => import('./carousel'));
const LazyImage = lazy(() => import('./lazyimage')); // Luo erillinen komponentti LazyImage kuvan lazy-loadaukseen
const BackToTop = () => {
  return (
    <div className="content absolute bottom-4 right-48 z-50 hover:scale-125 ease-in-out duration-300">
      <button
        className="bg-gray-800 text-white p-2 rounded-full"
        //  href="#home"

        onClick={() => {
          document.getElementById('home')?.scrollIntoView({ behavior: 'smooth' });
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
        </svg>
      </button>
    </div>
  );
};

const sectionStyle =
  'relative flex flex-col min-h-[75vh] snap-center p-4 z-1  rounded-2xl justify-center items-center bg-cyan-100 bg-opacity-45 text-slate-950 md:w-2/3 md:mx-auto font-semibold md:text-lg';
const pstyles = 'p-4 md:w-4/5';
const Content = ({ isMobile }) => {
  return (
    <div className="p-4 space-y-24 mt-0 courgette-regular bg-bgimg bg-cover bg-no-repeat snap-y snap-mandatory md:pb-32">
      <BackToTop />
      <section id="home" className={`${sectionStyle}+justify-between gap-12`}>
        <Suspense fallback={<div>Loading...</div>}>
          <LazyImage src={'./images/front-1.jpg'} alt="Mirka" className="rounded-full   max-w-72 " />
        </Suspense>
        <p className="text-xl md:w-4/5 text-center">{home}</p>
        <p className="text-xl md:w-4/5 text-center">{home2}</p>
      </section>
      <section id="karuselli" className={`${sectionStyle} `}>
        <Suspense fallback={<div>Loading...</div>}>
          <Karuselli isMobile={isMobile} />
        </Suspense>
      </section>

      <section id="about" className={`${sectionStyle}`}>
        <h2 className="text-3xl text-center font-bold py-4">About me</h2>
        <p className={pstyles + ' text-center'}>{p5}</p>
        <p className="p-4 md:w-4/5 text-center">{p1}</p>

        <p className={pstyles + ' text-center'}>{p2}</p>
        <p className={pstyles + ' text-center'}>{p3}</p>
      </section>
      <section id="projects" className={`${sectionStyle}`}>
        <h2 className="text-3xl font-bold text-center py-8 w-4/5">
          Are you ready to make a change? Let’s do this together!
        </h2>
        <p className={pstyles}> {p4}</p>
        <Reviews />
      </section>
      <section id="contact" className={`${sectionStyle}`}>
        <Form />
      </section>
    </div>
  );
};

export default Content;

const p4 = (
  <div>
    <p>Ready to transform your life and feel amazing inside and out? Let’s get started!</p>
    <p>
      In my online coaching program, we focus on fat loss and building muscle mass to help you become strong and feel
      good from the inside out. I believe in living our best possible life—healthy, long, and full of good energy.
    </p>
    <p>
      We don’t train just for summer; we train for life! I’m here to help you fall in love with sports and healthy
      habits.
    </p>
    <p>What you get with my online program:</p>
    <ul className="list-disc list-inside p-8">
      <li>Personalized workouts through the app, whether at the gym, at home, or a combination of both.</li>
      <li>Video demos of all exercises.</li>
      <li>Customized macros and a macro-friendly cookbook.</li>
      <li>No strict diets</li>
      <li>Clean eating with balance.</li>
      <li>
        Weekly check-ins and 24/7 access through the app to discuss progress, tackle challenges, and get ready to crush
        the new week.
      </li>
      <li>
        Mindset coaching to overcome what’s holding you back, with motivational strategies and support to tackle
        limiting beliefs.
      </li>
    </ul>
  </div>
);

const p1 = `  I’m passionate about helping people achieve their fitness goals
          and transform their lives. My approach involves creating challenging and fun workouts to keep people engaged
          and motivated throughout their fitness journey. I tailor all my workouts to suit a variety of fitness goals,
          from weight loss to strength training, helping individuals find joy in exercising and boosting their
          self-confidence. Overall, I am a skilled and supportive coach dedicated to helping my clients reach their full
          potential. `;

const p2 = `
          
          I come from the countryside of Finland, and from a young age, I have been competing in and
          participating in different kinds of sports, such as cross-country skiing, MMA, and track and field. I moved
          out of Finland 8 years ago, traveling and training (mostly in martial arts) all around the world before
          finding my peace and place in Phuket. Currently, I’m working as the head coach at Titan
          Fitness transformation bootcamp in Phuket, Thailand. I enjoy the island lifestyle and spend most of my time at
          the gym, boxing, going for jungle hikes, or relaxing at the beach or spa.`;

const p3 = `
          
          My current focus on speed training,
          agility, boxing, and functional fitness makes me a versatile trainer capable of working with athletes at
          different levels of experience. My willingness to provide modifications and progressions for individuals of
          all abilities is a great asset, ensuring that everyone can get the most out of their training sessions. Taking
          things one day at a time can help us avoid feeling overwhelmed and allow us to focus on making progress in our
          daily lives. Learning to enjoy the journey can help us appreciate the process of achieving our goals rather
          than solely focusing on the result. Finally, working together can often lead to more successful outcomes than
          going it alone. I believe that by adopting these principles, we can cultivate a mindset that allows us to
          achieve great things and enjoy the process along the way.`;

const p5 = `I’m a Certified Fitness Coach, Personal Trainer, and Reiki Energy Healer. Training is 15%, while the remaining 85% encompasses your nutrition, quality of sleep, stress management, hormonal balance, and mental and spiritual well-being. Sometimes we can achieve more by doing less, which doesn't mean being less. Sometimes doing less means choosing yourself more deeply. Sometimes going more slowly means giving yourself a better chance to thrive. Sometimes producing less means being more present in your life.`;

const home = `Hi! I'm Mirka, your Certified Fitness Coach and Personal Trainer. I'm here to help you achieve your fitness goals and transform your life. With a personalized approach that includes fun and challenging workouts, we'll focus on every aspect of your well-being: training, nutrition, sleep, stress management, and mental and spiritual health.

`;
const home2 = `Join me in embracing a balanced lifestyle where doing less can sometimes mean achieving more, and where going slowly can help you thrive. Let's work together to unlock your full potential and enjoy every step of the journey. Welcome to a community dedicated to your growth, peace, and abundance.`;
